import React from 'react';
import { lighten } from '@mui/material/styles';
import LandingSection from './../LandingSection';
import {
  Fingerprint,
  PrecisionManufacturing,
  UploadFile,
} from '@mui/icons-material';
import fiberpayLogo from '../../../images/fiberpay_logo.svg';
import Features from './../Features';

const products = [
  {
    name: 'Automatyczna weryfikacja',
    description: (
      <>
        Twórz kartoteki klientów i transakcji oraz dokonuj ich automatycznej
        weryfikacji, z wykorzystaniem list sankcyjnych
      </>
    ),
    icon: <Fingerprint sx={{ fontSize: { xs: 40, md: 60 } }} />,
    url: '/',
  },
  {
    name: 'Inteligentne wykrywanie',
    description: (
      <>Zautomatyzuj wykrywanie transakcji ponadprogowych i podejrzanych</>
    ),
    icon: <PrecisionManufacturing sx={{ fontSize: { xs: 40, md: 60 } }} />,
    url: '/products/fiberid/',
  },
  {
    name: 'Generowanie zawiadomień',
    description: <>Generuj informacje i zawiadomienia do GIIF</>,
    icon: <UploadFile sx={{ fontSize: { xs: 40, md: 60 } }} />,
    url: '/products/fiberid/',
  },
];

const Products = () => (
  <LandingSection
    columnText={false}
    sx={{
      marginTop: (theme) => theme.spacing(12),
      background: (
        theme
      ) => `url(${fiberpayLogo}) no-repeat center, linear-gradient(
    90deg,
    ${lighten(theme.palette.primary.lighter, 0.2)} 0%,
    ${theme.palette.primary.darker} 100%)`,
      marginLeft: 0,
      marginRight: 0,
    }}
    id='produkty'
    subtitle='Funkcjonalności'
    title='System AML pozwala spełnić obowiązki ustawowe związane z regulacjami AML'
    // colLeftText="Sprawnie zbuduj Twoje procesy onboardingu klientów, weryfikacji tożsamości, oceny
    //       ryzyka, autoryzacji transakcji, wielokanałowego przyjmowania płatności, naliczania prowizji od transakcji oraz
    //       dystrybucji wypłat - wszystko na jednej, łatwej w obsłudze platformie technologicznej."
    // colRightText="Obniż koszty budowy i utrzymania aplikacji oraz zapewnij bezpieczeństwo i zgodność z
    //       obowiązującymi regulacjami - bez rozwiązywania problemów ze współpracą rozwiązań od wielu różnych
    //       dostawców."
    actionButtonText='Sprawdź już teraz'
    // actionButtonUrl={"https://verify.fiberpay.pl/register/"}
  >
    <Features
      products={products}
      largeNum={4}
      buttonOn={false}
      avatarOff={true}
    />
  </LandingSection>
);

export default Products;
