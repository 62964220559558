import React from 'react';
import { lighten } from '@mui/material/styles';
import LandingSection from '../LandingSection';
import { LocalPolice, Gavel, Paid } from '@mui/icons-material';
import fiberpayLogo from '../../../images/fiberpay_logo.svg';
import Features from './../Features';

const products = [
  {
    name: 'Kary pienieżne',
    description: (
      <>
        Niespełnienie wymogów AML może skutkować nałożeniem kar na członków
        zarządu, kadrę kierowniczą spółki lub przedsiębiorcę prowadzącego
        działalność gospodarczą
      </>
    ),
    icon: <Paid sx={{ fontSize: { xs: 40, md: 60 } }} />,
    url: '/',
  },
  {
    name: 'Implikacje prawne',
    description: (
      <>
        Konsekwencje niezgodności z ustawami AML mogą mieć charakter
        administracyjny, pieniężny i karny
      </>
    ),
    icon: <Gavel sx={{ fontSize: { xs: 40, md: 60 } }} />,
    url: '/products/fiberid/',
  },
  {
    name: 'Utrata koncesji',
    description: (
      <>
        Nie spełniając wymogów ryzykujesz utratą koncesji lub zezwoleń, zakazem
        pełnienia obowiązków na stanowiskach kierowniczych, grzywnami
        sięgającymi nawet 5 mln euro, a nawet pozbawieniem wolności od 3
        miesięcy do 5 lat
      </>
    ),
    icon: <LocalPolice sx={{ fontSize: { xs: 40, md: 60 } }} />,
    url: '/products/fiberid/',
  },
];

const Products = () => (
  <LandingSection
    columnText={false}
    sx={{
      marginTop: (theme) => theme.spacing(12),
      background: (
        theme
      ) => `url(${fiberpayLogo}) no-repeat center, linear-gradient(
    90deg,
    ${lighten(theme.palette.primary.lighter, 0.2)} 0%,
    ${theme.palette.primary.darker} 100%)`,
      marginLeft: 0,
      marginRight: 0,
    }}
    id='produkty'
    subtitle='Ryzyka'
    title='Konsekwencje prawne z niestosowania regulacji AML'
    // colLeftText="Sprawnie zbuduj Twoje procesy onboardingu klientów, weryfikacji tożsamości, oceny
    //       ryzyka, autoryzacji transakcji, wielokanałowego przyjmowania płatności, naliczania prowizji od transakcji oraz
    //       dystrybucji wypłat - wszystko na jednej, łatwej w obsłudze platformie technologicznej."
    // colRightText="Obniż koszty budowy i utrzymania aplikacji oraz zapewnij bezpieczeństwo i zgodność z
    //       obowiązującymi regulacjami - bez rozwiązywania problemów ze współpracą rozwiązań od wielu różnych
    //       dostawców."
    actionButtonText='Sprawdź już teraz'
  >
    <Features
      products={products}
      largeNum={4}
      buttonOn={false}
      avatarOff={true}
    />
  </LandingSection>
);

export default Products;
