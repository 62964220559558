import React from "react";
import { Avatar, Paper, styled, Typography } from "@mui/material";

const PREFIX = "FiberAML-LandingList";
const classes = {
  card: `${PREFIX}-card`,
  avatar: `${PREFIX}-avatar`,
  typography: `${PREFIX}-typography`,
  typographyText: `${PREFIX}-typographyText`,
};

const RootPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  [`&.${classes.card}`]: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    paddingLeft: "8px",
    [theme.breakpoints.up("md")]: {
      paddingLeft: "38px",
    },
    paddingRight: "16px",
  },
  [`& .${classes.avatar}`]: {
    backgroundColor: "#6CA0DF",
    // backgroundColor: "#F2F2FA",
    // color: "#597efc",
    color: "#F2F2FA",
    fontSize: "small",
    width: theme.spacing(3.2),
    height: theme.spacing(3.2),
    marginRight: theme.spacing(2),
    // color: "#597efc",
  },
  [`& .${classes.typographyText}`]: {
    //alignSelf: "center",
    // color: theme.palette.grey[700],
    fontWeight: "500",
    fontSize: "20px",
    listStyleType: "none",
  },
}));

const LandingList = (props) => {
  const { icon, children } = props;
  return (
    <RootPaper className={classes.card} elevation={0}>
      <Avatar className={classes.avatar}>{icon}</Avatar>
      <Typography className={classes.typographyText}>{children}</Typography>
    </RootPaper>
  );
};

export default LandingList;
