import { Box } from '@mui/material';
import React from 'react';
import EndInfoBanner from '../EndInfoBanner';

// const textUp = [
//   '  Uniknij ryzyk niezgodności z regulacjami AML dzięki wykorzystaniu Systemu AML tożsamość klienta',
// ];

const textUp = (
  <>
    Uniknij ryzyk niezgodności z regulacjami AML dzięki wykorzystaniu Systemu
    AML tożsamość klienta
  </>
);

const textDown = null;

const textButton = <> Wypróbuj teraz </>;

const pageLink = 'https://systemaml.pl/';

const Pricing = () => (
  <>
    <Box
      sx={{
        height: { xs: '32px', sm: '96px' },
        width: '100%',
        zIndex: -1,
        background: '#EBF2FA',
      }}
    />
    <EndInfoBanner
      textGridhWidth={12}
      textUp={textUp}
      textUpFoWe={400}
      textDown={textDown}
      textDoFoWe={500}
      textButton={textButton}
      pageLink={pageLink}
    />
  </>
);

export default Pricing;
