import React, { Fragment } from "react";
import {
  Container,
  Box,
  Typography,
  Grid,
  styled,
  Divider,
} from "@mui/material";
import LandingList from "./LandingList";

const PREFIX = "FiberAML-FeaturesList";
const classes = {
  bold: `${PREFIX}-bold`,
  boldGray: `${PREFIX}-boldGray`,
  textSection: `${PREFIX}-textSection`,
  sectionFour: `${PREFIX}-sectionFour`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.bold}`]: {
    color: theme.palette.common.black,
    // fontWeight: "500",
    align: "center",
    fontSize: "40px",
  },
  [`& .${classes.boldGray}`]: {
    color: theme.palette.grey[700],
    fontWeight: "500",
    align: "center",
  },
  [`& .${classes.textSection}`]: {
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(8),
      marginLeft: theme.spacing(8),
      marginRight: theme.spacing(8),
    },
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
  },
  [`& .${classes.sectionFour}`]: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
    marginLeft: "37px",
  },
}));

const FeaturesList = ({ title, subtitle, items }) => (
  <Root>
    <Container component="section" py={3}>
      <Box className={classes.textSection}>
        <Typography className={classes.bold} align="center">
          {title}
        </Typography>
        <Typography
          variant="h6"
          paragraph
          className={classes.boldGray}
          align="center"
        >
          {subtitle}
        </Typography>
      </Box>
      <Box className={classes.sectionFour}>
        <Grid container spacing={4}>
          {items.map((item, index) => (
            <Fragment key={item.id}>
              <Grid item md={5.97} sm={5.97} xs={12}>
                <LandingList
                  icon={item.icon || index + 1}
                  header={item.name}
                  children={item.text}
                />
              </Grid>
              {index % 2 === 0 && (
                <Divider orientation="vertical" flexItem></Divider>
              )}
            </Fragment>
          ))}
        </Grid>
      </Box>
    </Container>
  </Root>
);

export default FeaturesList;
