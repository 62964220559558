import { Check } from '@mui/icons-material';
import React from 'react';
import FeaturesList from './featureslist';

const obligatedInstitutions = {
  title: 'Instytucje zobowiązane',
  subtitle: 'Lista działalności zobowiązanych do spełnienia regulacji AML',
  items: [
    {
      id: 'obligatedInstitutions1',
      name: '',
      text: 'Adwokaci i radcy prawni',
      icon: <Check />,
    },
    {
      id: 'obligatedInstitutions2',
      name: '',
      text: 'Banki i instytucje finansowe',
      icon: <Check />,
    },
    {
      id: 'obligatedInstitutions3',
      name: '',
      text: 'Biura rachunkowe',
      icon: <Check />,
    },
    {
      id: 'obligatedInstitutions4',
      name: '',
      text: 'Biura Usług Płatniczych',
      icon: <Check />,
    },
    {
      id: 'obligatedInstitutions5',
      name: '',
      text: 'Bukmacherzy',
      icon: <Check />,
    },
    {
      id: 'obligatedInstitutions7',
      name: '',
      text: 'Doradcy podatkowi',
      icon: <Check />,
    },
    {
      id: 'obligatedInstitutions8',
      name: '',
      text: 'Firmy pożyczkowe',
      icon: <Check />,
    },
    {
      id: 'obligatedInstitutions9',
      name: '',
      text: 'Fundusze inwestycyjne',
      icon: <Check />,
    },
    {
      id: 'obligatedInstitutions10',
      name: '',
      text: 'Instytucje pieniądza elektronicznego',
      icon: <Check />,
    },
    {
      id: 'obligatedInstitutions11',
      name: '',
      text: 'Kantory wymiany walut',
      icon: <Check />,
    },
    {
      id: 'obligatedInstitutions12',
      name: '',
      text: 'Krajowe Instytucje Płatnicze',
      icon: <Check />,
    },
    {
      id: 'obligatedInstitutions13',
      name: '',
      text: 'Notariusze',
      icon: <Check />,
    },
    {
      id: 'obligatedInstitutions14',
      name: '',
      text: 'Portale i domy aukcyjne',
      icon: <Check />,
    },
    {
      id: 'obligatedInstitutions15',
      name: '',
      text: 'Pośrednicy w sprzedaży nieruchomości',
      icon: <Check />,
    },
    {
      id: 'obligatedInstitutions16',
      name: '',
      text: 'Stowarzyszenia i fundacje',
      icon: <Check />,
    },
    {
      id: 'obligatedInstitutions17',
      name: '',
      text: 'Towarzystwa ubezpieczeniowe i pośrednicy',
      icon: <Check />,
    },
  ],
};

const ProductList = () => {
  return (
    <FeaturesList
      title={obligatedInstitutions.title}
      subtitle={obligatedInstitutions.subtitle}
      items={obligatedInstitutions.items}
    />
  );
};

export default ProductList;
