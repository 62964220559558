import React from 'react';
import Footer from '../../components/common/Footer';
import Hero from '../../components/landing/systemaml/Hero';
import Pricing from '../../components/landing/systemaml/Pricing';
import Products from '../../components/landing/systemaml/Products';
import ProductsList from '../../components/landing/systemaml/ProductsList';
import ProductsRisk from '../../components/landing/systemaml/ProductsRisk';
import ProductsFunction from '../../components/landing/systemaml/ProductsFunction';
import Seo from '../../seo';

const IndexPage = () => {
  return (
    <>
      <Seo
        title='System AML dla biur rachunkowych, notariuszy, kantorów i pozostałych instytucji 
        obowiązanych | Fiberpay'
        description='Weryfikuj klientów i ich transakcje SystemAML ułatwi Ci wypełnienienie 
        obowiązków ustawowych oraz uniknąć wysokich kar'
        metaImage='meta_image_systemaml.png'
        currentUrl='https://fiberpay.pl/produkty/system-aml'
      />
      <Hero />
      <Products />
      <ProductsList />
      <ProductsRisk />
      <ProductsFunction />
      <Pricing />
      <Footer />
    </>
  );
};

export default IndexPage;
