import React from 'react';
import fiberpayLogo from '../../../images/fiberpay_logo.svg';
import { lighten } from '@mui/material/styles';
import { FolderCopy, NotificationImportant } from '@mui/icons-material';
import Features from './../Features';
import LandingSection from '../LandingSection';

const products = [
  {
    name: 'Wymóg prawny',
    description: (
      <>
        Przeciwdziałanie praniu pieniędzy jest wymogiem prawnym prowadzenia
        działalności gospodarczej w Polsce jeżeli jesteś instytucją obowiązaną
      </>
    ),
    icon: <NotificationImportant sx={{ fontSize: { xs: 40, md: 60 } }} />,
    url: '/',
  },
  {
    name: 'Kontrola dokumentacji',
    description: (
      <>
        Obowiązek ten podlega kontroli - aby uniknąć ryzyka kar powinieneś
        dokumentować czynności, do jakich wymaga prawo
      </>
    ),
    icon: <FolderCopy sx={{ fontSize: { xs: 40, md: 60 } }} />,
    url: '/products/fiberid/',
  },
];

const Products = () => (
  <LandingSection
    columnText={false}
    sx={{
      marginTop: (theme) => theme.spacing(12),
      background: (
        theme
      ) => `url(${fiberpayLogo}) no-repeat center, linear-gradient(
    90deg,
    ${lighten(theme.palette.primary.lighter, 0.2)} 0%,
    ${theme.palette.primary.darker} 100%)`,
      marginLeft: 0,
      marginRight: 0,
    }}
    id='produkty'
    subtitle='Problemy'
    title='Konsekwencje wynikające z obowiązku stosowania regulacji AML'
    // colLeftText="Sprawnie zbuduj Twoje procesy onboardingu klientów, weryfikacji tożsamości, oceny
    //       ryzyka, autoryzacji transakcji, wielokanałowego przyjmowania płatności, naliczania prowizji od transakcji oraz
    //       dystrybucji wypłat - wszystko na jednej, łatwej w obsłudze platformie technologicznej."
    // colRightText="Obniż koszty budowy i utrzymania aplikacji oraz zapewnij bezpieczeństwo i zgodność z
    //       obowiązującymi regulacjami - bez rozwiązywania problemów ze współpracą rozwiązań od wielu różnych
    //       dostawców."
    actionButtonText='Sprawdź już teraz'
  >
    <Features
      products={products}
      largeNum={6}
      buttonOn={false}
      avatarOff={true}
    />
  </LandingSection>
);

export default Products;
